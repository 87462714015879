import './GameListEntry.css';
import CountryFlag from '../CountryFlag/CountryFlag';
import { GameEntry, Metadata } from '../../types';
import { CONSOLE_ICONS } from '../../constants';

export default function GameListEntry(props: {game: GameEntry, meta: Metadata, onClick: Function}) {
    const releaseDate: Date = new Date(props.game.releaseDate);
    const regionFlags: JSX.Element[] = props.meta.regions
        .filter((reg: any) => props.game.regions.includes(reg.id))
        .map((region: any) => <CountryFlag key={`${props.game.id}-${region.id}`} region={region}/>);
    const systemIcon = CONSOLE_ICONS[props.meta.systems[props.game.system].abbreviation] || null;
    const showRegions = props.game.regions.length > 1 || props.game.regions[0] !== "XX";
    const showVersions = props.game.versions > 1;
    const showNewRibbon = localStorage.getItem("lastSeenGame") !== null && props.game.id > parseInt(`${localStorage.getItem("lastSeenGame")}`);
    const releaseDateLabel = releaseDate.getTime() <= 0 ? "Unreleased" : releaseDate.getFullYear();

    return (
        <div className='game-entry' onClick={props.onClick as any}>
            {showNewRibbon && (
                <div className='ribbon ribbon-top-left'>
                    <span>New!</span>
                </div>
            )}
            <div className='game-cover-container'>
                <img alt="Game Cover" src={props.game.cover} className='game-cover'/>
            </div>
            <div className='game-summary'>
                <h2>
                    <span className='success'>[+]</span> {props.game.title} ({releaseDateLabel})
                </h2>
                <br/>
                <h3>
                    <span className='info'>[-]</span> {systemIcon} {props.meta.systems[props.game.system].name} ({props.meta.developers[props.game.developer].name})
                </h3>
                {showRegions && (
                    <h3>
                        <span className='info'>[-]</span> {regionFlags}{regionFlags.length} Available Region{regionFlags.length > 1 ? "s" : ""}
                    </h3>
                )}
                <br/>
                {showVersions && (
                    <>{props.game.versions} available files.</>
                )}
            </div>
        </div>
    );
}