import { useRef } from 'react';
import './OverlayBox.css';

export default function OverlayBox(props: {borderColor: "error" | "success" | "info", onClose?: Function, children: any}) {
    const backdrop = useRef(null);

    function onClick(e: React.MouseEvent<HTMLDivElement, MouseEvent>) {
        if(props.onClose && backdrop.current === e.target)
            props.onClose();
    }

    const cssColorVar = `var(--${props.borderColor}-color`;
    return <div className="overlay-background" ref={backdrop} onClick={onClick}>
        <div
            style={{
                borderColor: cssColorVar,
                boxShadow: `0 0 10px ${cssColorVar}`
            }}
            className="overlay-box"
        >
            {props.children}
        </div>
    </div>
}