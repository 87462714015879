import './Dropdown.css'
import { useState } from 'react';

export default function Dropdown(props: {children: JSX.Element[], onChange: Function}) {
    const [selectedElem, setSelectedElem] = useState<JSX.Element>(props.children[0]);

    function onChange(element: JSX.Element) {
        setSelectedElem(element);
        props.onChange(element.key);
    }

    return (
        <div className='dropdown'>
            <span>{selectedElem}</span>
            <div className='dropdown-content'>
                {props.children.map(elem => (
                    <div className='dropdown-element' onClick={() => onChange(elem)} key={`dropdown-elem-${elem.key}`}>
                        {elem}
                    </div>
                ))}
            </div>
        </div>
    )
}