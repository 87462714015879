import './CountryFlag.css'
import { Region } from "../../types";

export default function CountryFlag(props: {region: Region}) {
    const url = props.region.id === "XX"
        ? "https://upload.wikimedia.org/wikipedia/commons/2/2f/Flag_of_the_United_Nations.svg"
        : `http://purecatamphetamine.github.io/country-flag-icons/3x2/${props.region.id}.svg`;
    return <img
        className='country-flag'
        alt={props.region.id}
        src={url}
        title={props.region.name}
    />
}