import './RequestsBox.css';
import OverlayBox from "../OverlayBox/OverlayBox";
import { useState } from 'react';
import API from '../../API';

export default function RequestsBox(props: {onClose: Function}) {
    const [requestText, setRequestText] = useState<string>("");

    async function onSendRequest() {
        const requestRes = await API.request("request", "POST", {request: requestText});
        if(requestRes.status !== 201)
            alert("Please make sure your request is between 1 and 1024 characters long.");
        else {
            alert("Your request has been sent successfully!");
            props.onClose();
        }
    }

    return <OverlayBox borderColor="info" onClose={props.onClose}>
        <div className='requests-box'>
            <h1>Send Game / Feature Requests:</h1>
            <br/>
            <textarea
                className="cli-input request-textbox"
                rows={5}
                placeholder="Enter your request here..."
                onChange={(e) => setRequestText(e.target.value)}
                value={requestText}
            />
            <button className='request-button' onClick={() => onSendRequest()}>Send Request</button>
        </div>
    </OverlayBox>
}