import { useState } from 'react'
import './LoadingText.css'

const BUFFER_ANIM = "⣾⣽⣻⢿⡿⣟⣯⣷";

export default function LoadingText(props: {text: string, color?: "info" | "error" | "success"}) {
    const [buffer, setBuffer] = useState(BUFFER_ANIM[0]);
    setTimeout(() => setBuffer(BUFFER_ANIM[(BUFFER_ANIM.indexOf(buffer) + 1) % BUFFER_ANIM.length]), 250)

    return <h1 className='loading-text'>
        <span style={{color: `var(--${props.color || "info"}-color`}}>{buffer}</span>
        &nbsp;{props.text}
    </h1>
}