import './PlayLoadHeader.css'

export default function PlayLoadHeader(props: {onLogout: Function, onOpenRequests: Function}) {
    return <div className="playload-header">
        <div className="header-section"/>
        <img
            alt="PlayLoad Banner"
            className='playload-banner header-section'
            src='/banner.png'
            onClick={() => window.location.reload()}
        />
        <div className="header-buttons header-section">
            <button onClick={() => props.onOpenRequests()} className="logout-button">Feature/Game Request</button>
            <button onClick={() => props.onLogout()} className="logout-button">Logout</button>
        </div>
    </div>
}