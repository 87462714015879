import { useState } from "react";
import API from "../API";
import LoadingText from "./LoadingText/LoadingText";
import OverlayBox from "./OverlayBox/OverlayBox";

export default function APIHook(props: {children: JSX.Element[]}) {
    const [connected, setConnected] = useState<boolean>(false);
    const [error, setError]         = useState<boolean>(false);
    const [loading, setLoading]     = useState<boolean>(false);
    API.init(setConnected, setError, setLoading);

    // Register handler functions for updating last seen game IDs on closing site
    window.addEventListener("beforeunload", () => {
        const sessionMaxID = sessionStorage.getItem("maxGameID");
        const persistentMaxID = localStorage.getItem("lastSeenGame");
        if(sessionMaxID !== null) {
            if(persistentMaxID === null || parseInt(sessionMaxID) > parseInt(persistentMaxID))
                localStorage.setItem("lastSeenGame", `${sessionMaxID}`);
        }
    })

    /**
     * Index 0: Connecting
     * Index 1: Application
     * Index 2: Error
     */
    const bodyIndex = error ? 2 : connected ? 1 : 0;
    return <>
        {props.children[bodyIndex]}
        {loading &&
            <OverlayBox borderColor="info">
                <LoadingText text='Loading...'/>
            </OverlayBox>
        }
    </>
}