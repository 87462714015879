import { useState, useEffect } from 'react';
import jwtDecode from 'jwt-decode';
import API from '../API';
import GamesList from './GamesList/GamesList';
import Login from "./LoginPage/Login";
import MaintenancePage from './MaintenancePage/MaintenancePage';
import { AuthData, Metadata } from '../types';
import PlayLoadHeader from '../components/PlayLoadHeader/PlayLoadHeader';
import RequestsBox from '../components/RequestsBox/RequestsBox';

export default function Application() {
    const [auth, setAuth]         = useState<AuthData | null>(null);
    const [metadata, setMetadata] = useState<Metadata | null>(null);
    const [showReq, setShowReq]   = useState<boolean>(false);

    /** Attempts to use the token stored in localStorage to fetch metadata. Updates API state accordingly. */
    async function tryStoredToken() {
        // Attempt to fetch token from localStorage and request metadata
        API.token = localStorage.getItem("token");
        const metaRes = await API.request("metadata");

        // If request fails, remove token from localStorage and update API state
        if(metaRes.status !== 200) {
            API.token = null;
            localStorage.removeItem("token");
            return;
        }

        // If request succeeds, update API state and metadata object
        setAuth(jwtDecode(API.token as string));
        setMetadata(await metaRes.json());
    }
    
    /** Callback function for when the user successfully authenticates. Stores token in localStorage and fetches metadata. */
    async function onAuth(authRes: any) {
        // Update API state and store token in localStorage
        API.token = authRes.token;
        localStorage.setItem("token", authRes.token);

        // Fetch metadata and update state variables
        const metaRes = await API.request("metadata");
        const meta = await metaRes.json();
        setMetadata(meta);
        setAuth(authRes.payload);
    }

    /** Logs out the user by destroying the stored token and reloading the page */
    async function onLogout() {
        API.token = null;
        localStorage.removeItem("token");
        setAuth(null);
    }

    // --------------------------------------------------------------------------

    // Attempt to log in using stored token if present
    useEffect(() => {
        if(localStorage.getItem("token") !== null)
            tryStoredToken();
    }, []);
    
    // Navigate to maintenance page if hash is present in URL
    if(auth !== null && window.location.hash === "#maintenance")
        return <MaintenancePage/>

    // If user is logged in display GamesList, otherwise show Login Form
    return auth === null ? <Login onAuth={onAuth}/> : <>
        <PlayLoadHeader onLogout={onLogout} onOpenRequests={() => setShowReq(true)}/>
        <GamesList meta={metadata}/>
        {showReq && <RequestsBox onClose={() => setShowReq(false)}/>}
    </>
}