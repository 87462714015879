import './css/index.css';
import './css/variables.css';
import './css/common.css';
import './css/scrollbar.css';
import './css/animation.css';
import './css/cli-input.css';
import './css/console-icon.css';
import './css/ribbon.css';
import ReactDOM from 'react-dom/client';
import LoadingText from './components/LoadingText/LoadingText';
import OverlayBox from './components/OverlayBox/OverlayBox';
import APIHook from './components/APIHook';
import Application from './pages/Application';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <APIHook>
    {/*----------| Stuff below is only rendered while API is connecting |----------*/}
    <OverlayBox borderColor="info">
      <LoadingText text='Connecting to PlayLoad...'/>
    </OverlayBox>
    {/*----------| Stuff below is only rendered if API is connected |----------*/}
    <>
    <Application/>
    </>
    {/*----------| Stuff below is only rendered if API connection cannot be established |----------*/}
    <div>
      <OverlayBox borderColor="error">
        <h1 className='error'>Could not connect to PlayLoad.</h1>
        <p>
          Please check your internet connection and reload the page.<br/>
          If the problem persists, PlayLoad is currently offline for maintenance or due to a server-side error.
        </p>
      </OverlayBox>
    </div>
  </APIHook>
);