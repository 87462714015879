import './GameFilterOptions.css';
import Dropdown from '../Dropdown/Dropdown';
import { Metadata } from '../../types';
import CountryFlag from '../CountryFlag/CountryFlag';
import { CONSOLE_ICONS } from '../../constants';

export default function GameFilterOptions(props: {meta: Metadata, setFilterOption: (key: string, value: string) => any}) {

    return (
        <div className='filter-parent'>
            <h3>Filter Options:</h3>
            <div className='filter-options'>
                <div className='filter-section'>
                    <h4>Region:&nbsp;</h4>
                    <Dropdown onChange={(id: string) => props.setFilterOption("region", id)}>
                        {[
                            <div key="">None</div>,
                            ...props.meta.regions.map(region => (
                                <div key={region.id}>
                                    <CountryFlag region={region} key={region.id}/>
                                    <span>{region.name}</span>
                                </div>
                            ))
                        ]}
                    </Dropdown>
                </div>
                <div className='filter-section'>
                    <h4>System:&nbsp;</h4>
                    <Dropdown onChange={(id: string) => props.setFilterOption("system", id)}>
                        {[
                            <div key="">None</div>,
                            ...props.meta.systems.map(system => (
                                <div className='console-option' key={system.id}>
                                    {CONSOLE_ICONS[system.abbreviation] || null}
                                    &nbsp;
                                    {system.name}
                                </div>
                            ))
                        ]}
                    </Dropdown>
                </div>
            </div>
        </div>
    )
}