/** Array of URLs which the API class should attempt to establish a Citadel API connection to. */
export const API_URLS = [
    "https://localhost:42069",
    "https://localhost:6969",
    "https://citadel.ix.tc:42069",
    "https://citadel.ix.tc:6969",
];

/** Map for associating messages with HTTP status codes for login failure. */
export const LOGIN_STATUS_ERROR_MAP: {[key: number]: string} = {
    400: "Please enter username and password.",
    401: "Incorrect username or password."
}

/** Zero-width space unicode character. */
export const ZERO_WIDTH_SPACE = "\u200b"

export const CONSOLE_ICONS: {[console: string]: JSX.Element} = {
    "NES":       <img className="console-icon" alt="Nintendo Entertainment System" src="https://img.icons8.com/color/64/null/nintendo-entertainment-system.png"/>,
    "Game Boy":  <img className="console-icon" alt="Game Boy" src="https://img.icons8.com/external-anggara-flat-anggara-putra/64/null/external-game-boy-computer-device-anggara-flat-anggara-putra.png"/>,
    "SNES":      <img className="console-icon" alt="Super Nintendo" src="https://img.icons8.com/color/64/null/super-nintendo-entertainment-system.png"/>,
    "N64":       <img className="console-icon" alt="Nintendo 64" src="https://img.icons8.com/color/64/null/nintendo-64.png"/>,
    "GBC":       <img className="console-icon" alt="Game Boy Color" src="https://img.icons8.com/external-anggara-flat-anggara-putra/64/null/external-game-boy-computer-device-anggara-flat-anggara-putra.png"/>,
    "NGC":       <img className="console-icon" alt="Nintendo GameCube" src="https://img.icons8.com/color/64/null/nintendo-gamecube.png"/>,
    "GBA":       <img className="console-icon" alt="Game Boy Advance" src="https://img.icons8.com/color/64/null/visual-game-boy.png"/>,
    "NDS":       <img className="console-icon" alt="Nintendo DS" src="https://img.icons8.com/offices/64/null/nintendo-ds.png"/>,
    "Wii":       <img className="console-icon" alt="Nintendo Wii" src="https://img.icons8.com/color/64/null/wii.png"/>,
    "WiiU":      <img className="console-icon" alt="Nintendo Wii U" src="https://img.icons8.com/office/64/null/nintendo-wii-u.png"/>,
    "3DS":       <img className="console-icon" alt="Nintendo DS" src="https://img.icons8.com/offices/64/null/nintendo-ds.png"/>,
    "Switch":    <img className="console-icon" alt="Nintendo Switch" src="https://img.icons8.com/offices/64/null/nintendo-switch-logo.png"/>,
    "PS1":       <img className="console-icon" alt="PlayStation" src="https://img.icons8.com/color/64/null/play-station.png"/>,
    "PS2":       <img className="console-icon" alt="PlayStation 2" src="https://img.icons8.com/color/64/null/ps2.png"/>,
    "PS3":       <img className="console-icon" alt="PlayStation 3" src="https://img.icons8.com/color/64/null/ps3.png"/>,
    "PSP":       <img className="console-icon" alt="PlayStation Portable" src="https://img.icons8.com/external-filled-outline-perfect-kalash/64/null/external-playstation-hardware-devices-and-gadgets-filled-outline-perfect-kalash.png"/>,
    "SMS":       <img className="console-icon" alt="Sega Master System" src="https://img.icons8.com/external-tal-revivo-color-tal-revivo/64/null/external-sega-a-japanese-multinational-video-game-developer-and-publisher-company-logo-color-tal-revivo.png"/>,
    "Genesis":   <img className="console-icon" alt="Sega Mega Drive" src="https://img.icons8.com/color/48/null/sega-mega-cd-system.png"/>,
    "Game Gear": <img className="console-icon" alt="Sega Game Gear" src="https://img.icons8.com/external-tal-revivo-color-tal-revivo/64/null/external-sega-a-japanese-multinational-video-game-developer-and-publisher-company-logo-color-tal-revivo.png"/>,
    "segacd":    <img className="console-icon" alt="Sega CD" src="https://img.icons8.com/external-tal-revivo-color-tal-revivo/64/null/external-sega-a-japanese-multinational-video-game-developer-and-publisher-company-logo-color-tal-revivo.png"/>,
    "Saturn":    <img className="console-icon" alt="Sega Saturn" src="https://img.icons8.com/external-tal-revivo-color-tal-revivo/64/null/external-sega-a-japanese-multinational-video-game-developer-and-publisher-company-logo-color-tal-revivo.png"/>,
    "DC":        <img className="console-icon" alt="Sega Dreamcast" src="https://img.icons8.com/color/64/null/dreamcast.png"/>,
    "PC":        <img className="console-icon" alt="PC" src="https://img.icons8.com/color/64/null/workstation.png"/>,
}