import './GameDetailsOverlay.css'
import OverlayBox from "../OverlayBox/OverlayBox";
import API from '../../API';
import DownloadButton from '../DownloadButton/DownloadButton';
import { FileDownload, GameDetails, Metadata, Region } from '../../types';
import { CONSOLE_ICONS } from '../../constants';

export default function GameDetailsOverlay(props: {meta: Metadata, details: GameDetails, onClose: Function}) {
    const systemIcon = CONSOLE_ICONS[props.meta.systems[props.details.system].abbreviation] || null;
    const releaseDate = new Date(props.details.releaseDate);
    const downloads = props.details.files.map((file: FileDownload) => ({
        dl: API.getDownloadLink(file.dl),
        region: props.meta.regions.find(reg => reg.id === file.region) as Region,
        rev: file.rev,
        size: file.size
    }));
    const releaseDateLabel = releaseDate.getTime() <= 0 ? "Unreleased" : releaseDate.getFullYear();

    return <OverlayBox borderColor="info" onClose={props.onClose}>
        <div className='game-details'>
            <img alt="Game Cover" className='game-details-cover entrance-pop' src={props.details.cover}/>
            <div className='game-details-summary'>
                <h2>
                    <span className='success'>[+]</span> {props.details.title} ({releaseDateLabel})
                </h2>
                <br/>
                <h3>
                    <span className='info'>[-]</span> {systemIcon} {props.meta.systems[props.details.system].name} ({props.meta.developers[props.details.developer].name})
                </h3>
                <p>
                    {props.details.description}
                </p>
                <h3>
                    <span className='error'>[-]</span> Downloads: {props.details.downloads}
                </h3>
                <h3>
                    <span className='error'>[-]</span> Added to PlayLoad: {new Date(props.details.added).toLocaleDateString()}
                </h3>
                <DownloadButton downloads={downloads}/>
            </div>
        </div>
    </OverlayBox>
}