import { useState } from 'react';
import API from '../../API';
import OverlayBox from '../../components/OverlayBox/OverlayBox';
import { LOGIN_STATUS_ERROR_MAP, ZERO_WIDTH_SPACE } from '../../constants';
import './Login.css';

export default function Login(props: {onAuth: Function}) {
    const [username, setUsername] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [error, setError]       = useState<string>(ZERO_WIDTH_SPACE);

    async function onLogin() {
        setError(ZERO_WIDTH_SPACE)
        const auth = await API.request("login", "POST", {username, password});
        if(auth.status !== 200)
            setError(LOGIN_STATUS_ERROR_MAP[auth.status] || "An error occurred.");
        else
            props.onAuth(await auth.json())
    }

    function onKeyDown(e: React.KeyboardEvent<HTMLInputElement>) {
        if(e.key === "Enter")
            onLogin();
    }

    return <OverlayBox borderColor="info">
        <div className="login-container">
            <h1 className='info'>[+] PlayLoad Login</h1>
            <br/>
            <div>
                <label>
                    <span className='info'>[-] </span>
                    Username:&nbsp;
                </label>
                <input
                    autoFocus
                    className="cli-input"
                    onChange={(e) => setUsername(e.target.value)}
                    value={username}
                    onKeyDown={onKeyDown}
                />
            </div>
            <div>
                <label>
                    <span className='info'>[-] </span>
                    Password:&nbsp;
                </label>
                <input
                    className="cli-input"
                    type="password"
                    onChange={(e) => setPassword(e.target.value)}
                    value={password}
                    onKeyDown={onKeyDown}
                />
            </div>
            <br/>
            <button onClick={() => onLogin()} style={{width: "100%"}}>Login</button>
            <span className='error'>{error}</span>
        </div>
    </OverlayBox>;
}