import './GamesList.css';
import { useState, useEffect } from 'react';
import API from '../../API';
import GameListEntry from '../../components/GameListEntry/GameListEntry';
import GameDetailsOverlay from '../../components/GameDetailsOverlay/GameDetailsOverlay';
import Paginator from '../../components/Paginator/Paginator';
import { FilterOptions, GameDetails, GameEntry, GameEntryList, Metadata } from '../../types';
import GameFilterOptions from '../../components/GameFilterOptions/GameFilterOptions';

const EMPTY_FILTER_OPTIONS: FilterOptions = {
    region: "",
    system: ""
};

export default function GamesList(props: {meta: Metadata | null}) {
    const [page, setPage]                               = useState<number>(1);
    const [pageSize, setPageSize]                       = useState<number>(parseInt(localStorage.getItem("pageSize") || "10"));
    const [maxPage, setMaxPage]                         = useState<number>(0);
    const [games, setGames]                             = useState<GameEntry[]>([]);
    const [details, setDetails]                         = useState<GameDetails | null>(null)
    const [searchTerm, setSearchTerm]                   = useState<string>("");
    const [effectiveSearchTerm, setEffectiveSearchTerm] = useState<string>("");
    const [filterOptions, setFilterOptions]             = useState<FilterOptions>(EMPTY_FILTER_OPTIONS);
    const [totalGames, setTotalGames]                   = useState<number>(0);
    const [matchingGames, setMatchingGames]             = useState<number>(0);

    function setFilterOption(key: string, value: string) {
        setPage(1);
        const newFilterOptions: any = {...filterOptions};
        newFilterOptions[key] = value;
        setFilterOptions(newFilterOptions);
    }

    /** Loads games with current page and pageSize parameters and stores result in `games`. Automatically updates `maxPage`. */
    async function loadGames() {
        // Fetch game list from API
        const gamesRes: Response   = await API.request(`games?size=${pageSize}&page=${page}&searchQuery=${effectiveSearchTerm}&region=${filterOptions.region}&system=${filterOptions.system}`);
        const games: GameEntryList = await gamesRes.json();

        // Scroll to top of page
        document.body.scrollTop = document.documentElement.scrollTop = 0;

        // Set state variables accordingly
        setMaxPage(games.maxPage);
        setGames(games.games);

        // Store pageSize in localStorage
        localStorage.setItem("pageSize", ""+pageSize);

        // Store highest game ID to update lastSeenGame
        const maxID = Math.max(...games.games.map(game => game.id));
        if(sessionStorage.getItem("maxGameID") === null || parseInt(""+sessionStorage.getItem("maxGameId")) < maxID)
            sessionStorage.setItem("maxGameID", `${maxID}`);

        // If selected page is out of bounds, reset to last available page
        if(games.maxPage < page)
            setPage(games.maxPage);

        // Update game counts
        setTotalGames(games.total);
        setMatchingGames(games.match);
    }

    /** Fetches game details object for game with the given ID and updates `details` accordingly. */
    async function loadGameDetails(id: number) {
        const detailsRes: Response = await API.request(`game?id=${id}`);
        const details: GameDetails = await detailsRes.json();
        setDetails(details);
    }

    /** Closes the game details overlay by setting `details` to `null`. */
    async function closeGameDetails() {
        setDetails(null);
    }

    /** Checks if entered search term is different from before, if so, reloads game list. Automatically resets `page` to `1`. */
    function onStartSearch() {
        if(searchTerm !== effectiveSearchTerm) {
            setPage(1);
            setEffectiveSearchTerm(searchTerm);
        }
    }

    // --------------------------------------------------------------------------

    // Reload games whenever page, page size, filter options or search term change
    useEffect(() => {
        loadGames();
    }, [page, pageSize, effectiveSearchTerm, filterOptions]); // eslint-disable-line react-hooks/exhaustive-deps

    // Render page
    return (
        <>
            <div className='games-list-parent'>
                {/* Game Title Search Bar */}
                <div className='games-search-container'>
                    <input
                        className='games-search-bar'
                        placeholder='Search Games...'
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        onKeyDown={(e) => {if(e.key === "Enter") onStartSearch()}}
                    />
                    <button
                        className='games-search-button'
                        onClick={() => onStartSearch()}
                    >🔍</button>
                </div>

                {/* Display filter options below search bar */}

                {props.meta && <GameFilterOptions meta={props.meta as Metadata} setFilterOption={setFilterOption}/>}

                {/* Display game count */}
                {totalGames > 0 && (
                    totalGames !== matchingGames
                        ? <h3>Showing {matchingGames} out of {totalGames} games</h3>
                        : <h3>Showing all {totalGames} games</h3>
                )}

                {/* Display search term if available */}
                {effectiveSearchTerm &&
                    <h3>Search results for: '{effectiveSearchTerm}'</h3>
                }

                {/* List of loaded games */}
                <div className='games-list'>
                    {games && games.map(game =>
                        <GameListEntry
                            onClick={() => loadGameDetails(game.id)}
                            key={game.id}
                            game={game}
                            meta={props.meta as Metadata}
                        />
                    )}
                    {games.length === 0 && (
                        <h2 className='no-search-results-label'>Nothing to show here. :(</h2>
                    )}
                </div>

                {/* Paginator in Site Footer */}
    	        <Paginator
                    page={page}
                    maxPage={maxPage}
                    pageSize={pageSize}
                    onChangePage={setPage}
                    onChangePageSize={setPageSize}
                />
                
                {/* If game selected, display game details */}
                {details &&
                    <GameDetailsOverlay
                        onClose={closeGameDetails}
                        meta={props.meta as Metadata}
                        details={details}
                    />
                }
            </div>
        </>
    )
}