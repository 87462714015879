import { API_URLS } from "./constants";
import { BoolSetFunc } from "./types";

export default class API {

    /** Whether or not the API class has already been initialized. */
    static initialized:  boolean       = false;
    /** The verified URL under which the Citadel API can be reached. */
    static baseUrl:      string        = API_URLS[0];
    /** Whether or not the API class has an active connection- */
    static connected:    boolean       = false;
    /** Amount of URLs with which establishing a connection failed. */
    static failed:       number        = 0;

    /** Authorization token returned by the login endpoint. */
    static token:        string | null = null;

    /** Callback function to be called when the API is busy fetching data or has finished doing so. */
    static setLoading:   BoolSetFunc;
    /** Callback function to be called when an API connection error occurs. */
    static setError:     BoolSetFunc;
    /** Callback function to be called when the API connection state changes. */
    static setConnected: BoolSetFunc;

    /**
     * Registers all relevant callback functions to the API class and attempts to connect to the Citadel API.
     * @param setConnected Callback function to be called when the API connection state changes.
     * @param setError Callback function to be called when an API connection error occurs.
     * @param setLoading Callback function to be called when the API is busy fetching data or has finished doing so.
     */
    static async init(setConnected: BoolSetFunc, setError: BoolSetFunc, setLoading: BoolSetFunc): Promise<void> {
        // Return if API is already initialized
        if(API.initialized)
            return;
        API.initialized = true;

        // Register callbacks
        API.setLoading = setLoading;
        API.setError = setError;
        API.setConnected = setConnected;

        // Attempt connection to ping endpoint of each API URL, if successful, update API state accordingly
        for(let url of API_URLS) {
            API.attemptConnection(url).then(success => {
                if(!API.connected && success) {
                    console.log(`[API] Connected to ${url}`);
                    API.baseUrl = url;
                    API.connected = true;
                    setConnected(true);
                }
                else {
                    // If no API URL manages to establish a connection, go to error state
                    if(++API.failed === API_URLS.length)
                        setError(true);
                }
            })
        }
    }

    /** Returns a download link for a file with a given ID. */
    static getDownloadLink(id: string): string {
        return `${API.baseUrl}/playload/download?file=${id}&token=${API.token}`;
    }

    /**
     * Sends a request to the Citadel PlayLoad API using the given parameters.
     * @param endpoint The endpoint the request should be directed to.
     * @param method The HTTP method which should be used to send the request. Set to `GET` by default.
     * @param body The JSON body which should be attached to the request. No body will be sent if not provided.
     * @returns The `Response` object returned by the `fetch()` call.
     */
    static async request(endpoint: string, method: string = "GET", body?: object): Promise<Response> {
        // Tell UI that API is busy
        API.setLoading(true);

        // Put together fetch() call options object
        const options: any = {
            method,
            body: JSON.stringify(body),
            headers: {
                "Content-Type": "application/json"
            }
        };

        // If API token is set, add to request as Authorization header
        if(API.token)
            options.headers['Authorization'] = `Bearer ${API.token}`;
        
        try {
            // Send request, tell UI that API is no longer busy, return Response
            const data = await fetch(`${API.baseUrl}/playload/${endpoint}`, options);
            API.setLoading(false);
            return data;
        } catch(e) {
            // Go to error state if connection fails
            API.setLoading(false);
            API.setConnected(false);
            API.setError(true);
            throw new Error("[API] Lost connection.")
        }
    }

    /** Attempts to connect to the Citadel API Ping endpoint given a URL. Resolves to `true` if request successfully returns status code 200. */
    static async attemptConnection(url: string): Promise<boolean> {
        try {
            return (await fetch(`${url}/global/ping`)).status === 200;
        } catch(e) {
            return false;
        }
    }

}