import './MaintenancePage.css'
import Container from "../../components/Container/Container";
import { useState } from 'react';
import API from '../../API';

const EMPTY_GAME_META = {
    cover: "",
    dev: "",
    name: "",
    releaseDate: "",
    summary: "",
    system: ""
};

const EMPTY_FILE_DATA = {
    path: "",
    region: "",
    rev: ""
}

const FILENAME_AUTOFILL_MAP: {[keyword: string]: {[key: string]: string}} = {
    "(World)":                 {region: "XX"},
    "(Europe)":                {region: "EU"},
    "(USA)":                   {region: "US"},
    "(USA, Australia)":        {region: "US"},
    "(Japan)":                 {region: "JP"},
    "(Germany)":               {region: "DE"},
    "(France)":                {region: "FR"},
    "(Canada)":                {region: "CA"},
    "(Italy)":                 {region: "IT"},
    "(Spain)":                 {region: "ES"},
    "(Netherlands)":           {region: "NL"},
    "(Australia)":             {region: "AU"},
    "(Korea)":                 {region: "KR"},
    "(China)":                 {region: "CN"},
    "(USA, Europe)":           {region: "XX"},
    "(Rev 1)":                 {rev: "Revision 1"},
    "(Rev 2)":                 {rev: "Revision 2"},
    "(Rev 3)":                 {rev: "Revision 3"},
    "(Rev A)":                 {rev: "Revision A"},
    "(Rev B)":                 {rev: "Revision B"},
    "(Rev C)":                 {rev: "Revision C"},
    "(Beta)":                  {rev: "Beta Version"},
    "(Proto)":                 {rev: "Prototype"},
    "(NP)":                    {rev: "Nintendo Power"},
    "(Demo)":                  {rev: "Demo Version"},
    "(Kiosk)":                 {rev: "Kiosk Demo"},
    "(Wii Virtual Console)":   {rev: "Wii Virtual Console"},
    "(Wii U Virtual Console)": {rev: "Wii U Virtual Console"},
    "(Debug Version)":         {rev: "Debug Version"},
    "(Collector's Edition)":   {rev: "Collector's Edition"},
    "(Sample)":                {rev: "Sample Version"},
    "(Disc 1)":                {rev: "Disc 1"},
    "(Disc 2)":                {rev: "Disc 2"},
    "(Disc 3)":                {rev: "Disc 3"},
    ".nsz":                    {region: "XX"},
    ".nsp":                    {region: "XX"},
    "[v0]":                    {rev: "Base Game"}
}

export default function MaintenancePage() {
    const [autofillTitle, setAutofillTitle] = useState("");
    const [addGameMeta, setAddGameMeta] = useState(EMPTY_GAME_META);
    const [addFileData, setAddFileData] = useState(EMPTY_FILE_DATA);
    const [fileData, setFileData] = useState<any[]>([]);
    const [altNames, setAltNames] = useState<string[]>([]);

    async function onAddGame() {
        const body = {
            gameMeta: addGameMeta,
            files: fileData
        };
        const addRes = await API.request("maintenance/game", "PUT", body);
        if(addRes.status === 201)
            alert("Successfully added game!")
    }

    function onAddFile() {
        const newFileData = [...fileData];
        newFileData.push(addFileData as any);
        setFileData(newFileData);
        setAddFileData(EMPTY_FILE_DATA);
    }

    function setAddFileDataKey(e: React.ChangeEvent<HTMLInputElement>) {
        const newData: any = {...addFileData};
        newData[e.target.name] = e.target.value;
        
        if(e.target.name === "path") {
            newData.rev = "";
            newData.region = "";
            for(let keyword of Object.keys(FILENAME_AUTOFILL_MAP)) {
                if(e.target.value.includes(keyword)) {
                    for(let replaceKey of Object.keys(FILENAME_AUTOFILL_MAP[keyword]))
                        newData[replaceKey] = FILENAME_AUTOFILL_MAP[keyword][replaceKey];
                }
            }
        }

        setAddFileData(newData);
    }

    function setAddGameMetaKey(e: React.ChangeEvent<HTMLInputElement>) {
        const newMeta: any = {...addGameMeta};
        newMeta[e.target.name] = e.target.value;
        setAddGameMeta(newMeta);
    }

    async function onConfirmAutofill() {
        setAddGameMeta(EMPTY_GAME_META);
        setAddFileData(EMPTY_FILE_DATA);
        setFileData([]);

        let gameDataRes: any = null;
        let game: any = null;
        let accepted = null;
        let offset = 0;
        while(accepted === null) {
            gameDataRes = await API.request(`maintenance/game?name=${autofillTitle}&offset=${offset}`);
            if(gameDataRes.status !== 200) {
                alert(`Status ${gameDataRes.status}: ${gameDataRes.statusText}`);
                return;
            }

            game = (await gameDataRes.json());
            if(!game.name) game.name = "";
            if(!game.releaseDate) game.releaseDate = 0;
            if(!game.platforms) game.platforms = [];
            if(!game.devs) game.devs = [];
            const releaseDate = new Date(game.releaseDate || 0);
            accepted = prompt(`Is ${game.name} released in ${releaseDate.getFullYear()} for ${game.platforms.join(" / ")} the correct game?`);
            offset++;
        }

        if(game.altNames)
            setAltNames(game.altNames);

        for(let dev of game.devs) {
            const confirm = prompt(`Is ${dev} the correct developer?`);
            if(confirm !== null) {
                game.dev = dev;
                delete game.devs;
                break;
            }
        }
        if(!game.dev) {
            game.dev = "";
            delete game.devs;
        }

        for(let sys of game.platforms) {
            const confirm = prompt(`Is ${sys} the correct system?`);
            if(confirm !== null) {
                game.system = sys;
                delete game.platforms;
                break;
            }
        }
        if(!game.system) {
            game.system = "";
            delete game.platforms;
        }

        setAddGameMeta(game);
    }

    function onPressEnter(e: React.KeyboardEvent<HTMLInputElement>, callback: Function) {
        if(e.key === "Enter")
            callback();
    }

    return <>
        <Container>
            <div className='section'>
                <h1>PlayLoad Maintenance Section</h1>
            </div>
            <div className='section'>
                <h2>Add Game</h2>
                <div className='subsection'>
                    <label>Autofill by IGDB: </label><input
                        className="cli-input"
                        placeholder='Game Title...'
                        onChange={e => setAutofillTitle(e.target.value)}
                        onKeyDown={e => onPressEnter(e, onConfirmAutofill)}
                        value={autofillTitle}
                    /><br/>
                    <label>Alternate Names: </label>
                    {altNames.join(" | ")}
                    <br/><br/>

                    <h3>Game Metadata:</h3>
                    <div className='metadata-input'>
                        <label>Title: </label><input
                            className="cli-input"
                            placeholder='Game Title...'
                            onChange={setAddGameMetaKey}
                            name="name"
                            value={addGameMeta.name}
                        /><br/>
                        <label>Summary: </label><input
                            className="cli-input"
                            placeholder='Game Summary...'
                            onChange={setAddGameMetaKey}
                            name="summary"
                            value={addGameMeta.summary}
                        /><br/>
                        <label>Release Date: </label><input
                            className="cli-input"
                            placeholder='Release Date...'
                            onChange={setAddGameMetaKey}
                            name="releaseDate"
                            value={addGameMeta.releaseDate}
                        /><br/>
                        <label>Developer: </label><input
                            className="cli-input"
                            placeholder='Game Developer...'
                            onChange={setAddGameMetaKey}
                            name="dev"
                            value={addGameMeta.dev}
                        /><br/>
                        <label>System: </label><input
                            className="cli-input"
                            placeholder='Game System...'
                            onChange={setAddGameMetaKey}
                            name="system"
                            value={addGameMeta.system}
                        /><br/>
                        <label>Cover: </label><input
                            className="cli-input"
                            placeholder='Game Cover...'
                            onChange={setAddGameMetaKey}
                            name="cover"
                            value={addGameMeta.cover}
                        /><br/>
                    </div>
                    <img src={addGameMeta.cover} alt="Game Cover"/><br/>

                    <label>File Path: </label><input
                        className="cli-input"
                        placeholder='File Path...'
                        onChange={setAddFileDataKey}
                        onKeyDown={(e) => onPressEnter(e, onAddFile)}
                        name="path"
                        value={addFileData.path}
                    /><br/>
                    <label>Region: </label><input
                        className="cli-input"
                        placeholder='Region...'
                        onChange={setAddFileDataKey}
                        onKeyDown={(e) => onPressEnter(e, onAddFile)}
                        name="region"
                        value={addFileData.region}
                    /><br/>
                    <label>Revision: </label><input
                        className="cli-input"
                        placeholder='Revision...'
                        onChange={setAddFileDataKey}
                        onKeyDown={(e) => onPressEnter(e, onAddFile)}
                        name="rev"
                        value={addFileData.rev}
                    /><br/><br/>
                    <button onClick={onAddFile}>Add File</button>
                    <div className='gamefile-input'>
                        {fileData.map((data: any) => <>
                            <div className='gamefile-data'>
                                <p>{data.path}</p>
                                <p>{data.region}</p>
                                <p>{data.rev}</p>
                            </div>
                        </>)}
                    </div>

                    <br/><br/><button onClick={onAddGame}>Add Game</button>
                </div>
            </div>
        </Container>
    </>
}