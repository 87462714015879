import './DownloadButton.css'
import CountryFlag from "../CountryFlag/CountryFlag";
import { FileDownload, Region } from '../../types';

export default function DownloadButton(props: {downloads: FileDownload[]}) {
    let showFlags = props.downloads.filter(dl => (dl.region as Region).id !== "XX").length > 0;

    return props.downloads.length > 1 ? (
        <div className='dl-dropdown'>
            <button className='download-button dl-dropdown'>
                <div className='download-button-text'>Download</div>
                <div className='download-button-dropdown-arrow'>▼</div>
            </button>
            <div className='dl-dropdown-content'>
                {props.downloads.map((file: FileDownload) => (
                    <div
                        className='download-link'
                        key={file.dl}
                        onClick={() => window.open(`${file.dl}`, "_self")}
                    >
                        {showFlags && <CountryFlag region={file.region as Region}/>}
                        {file.rev || "Original Release"} ({file.size})
                    </div>
                ))}
            </div>
        </div>
    ) : (
        <a href={props.downloads[0].dl}>
            <button className='download-button dl-dropdown'>
                <div className='download-button-text'>Download ({props.downloads[0].size})</div>
            </button>
        </a>
    )
}