import './Paginator.css'

const PAGINATOR_SIZES = [5, 10, 25, 50];

export default function Paginator(props: {page: number, maxPage: number, pageSize: number, onChangePage: Function, onChangePageSize: Function}) {

    function onPressButtonAbsolute(newPage: number) {
        if(props.page !== newPage)
            props.onChangePage(newPage);
    }

    function getPageButtons() {
        if(props.maxPage < 1)
            return [];
            
        const buttons = [getPageButton(1)];
        let lastAdded = 1;
        for(let i = -2; i <= 2; i++) {
            if(props.page + i > 1 && props.page + i < props.maxPage) {
                lastAdded = props.page + i;
                if(i === -2 && lastAdded !== 2)
                    buttons.push(getPageSeparator(lastAdded));
                buttons.push(getPageButton(props.page + i));
            }
        }
        if(props.maxPage !== 1) {
            if(lastAdded !== props.maxPage - 1)
                buttons.push(getPageSeparator(lastAdded));
            buttons.push(getPageButton(props.maxPage));
        }
        return buttons;
    }

    function getPageButton(page: number) {
        const cssClass = "paginator-button" + (page === props.page ? " paginator-highlight-button" : "");
        return <button key={`page-${page}`} className={cssClass} onClick={() => onPressButtonAbsolute(page)}>[{page}]</button>
    }

    function getPageSeparator(id: number) {
        return <span key={`separator-${id}`}>...</span>
    }

    return (
        <div className='paginator-container'>
            <div className="paginator-container-child"/>
            <div className="paginator paginator-container-child">
                {getPageButtons()}
            </div>
            <div className="paginator-size-settings paginator-container-child">
                <h3>Items per Page:</h3>
                {PAGINATOR_SIZES.map(size => {
                    const cssClass = "paginator-button" + (props.pageSize === size ? " paginator-highlight-button" : "");
                    return <button key={`page-size-${size}`} className={cssClass} onClick={() => props.onChangePageSize(size)}>[{size}]</button>;
                })}
            </div>
        </div>
    )
}